<template>
    <div id="app_entry">
        <container :menu="menu" :showLeft="true" canHide>
            <keep-alive :exclude="unCachesComponents">
                <router-view />
            </keep-alive>
        </container>
    </div>
</template>
<script type="text/javascript">
import container from "@/components/jyui/components/container";
import {mapGetters} from "vuex";
import {API_WEBSOCKET,API_ALERT_SOCKET} from "@/config/env";
import _ from "loadsh";
export default {
    data() {
        return {
            menu: [],

            unCachesComponents: ["todoList","doneList","list","inStore","outStore","allot","settlement"],

            websocket: null
        };
    },
    components: {
        container
    },
    mounted() {
        //通过定时器轮询最新的待办任务
        this.initWs();
    },
    activated() {
        this.getNav();
    },
    methods: {
        getNav() {
            this.$http({
                url: "/sys/menu/tree"
            }).then(res => {
                this.menu = res.detail;
                if (this.menu.length == 0) {
                    this.$alert(
                        "您没有此系统的权限，请联系管理员分配权限",
                        "权限不够",
                        {
                            confirmButtonText: "确定",
                            callback: () => {
                                this.$store.dispatch("logout");
                            }
                        }
                    );
                }else{
                    const urls = _.compact(_.flatMapDeep(this.menu,(menu => {
                        return [menu,menu.children]
                    }))).map(v => v.url)
                    this.$store.dispatch("setUrls",urls)
                    if (!urls.includes(this.$route.path)) {
                       this.$router.replace(this.getRoute(this.menu[0]))
                    }
                }
            });
        },
        initWs() {
            this.websocket = new WebSocket(API_WEBSOCKET + API_ALERT_SOCKET + this.$util.getStor("token"))
            this.websocket.onopen = function () {
                console.log("ws open")
            }
            this.websocket.onmessage = function (data) {
                console.log(data)
                this.getNewTask()
            }
            this.websocket.onerror = function () {
                console.log("ws error")
            }
            this.websocket.onclose = function () {
                console.log("ws close")
            }
        },
        getRoute(route) {
            if (route.children) {
                return this.getRoute(route.children[0])
            }else{
                return route.url
            }
        },
        getNewTask() {
            //轮询最新任务
            if (this.$route.path != "/login") {
                this.$notify.info({
                    title: "任务提醒",
                    message: "您有新的维修任务需要办理，请及时处理！",
                    duration: 3 * 1000,
                    position: "bottom-right",
                    onClick: this.clickNotify
                });
            }
        },
        clickNotify() {
            this.$notify.close();
            if (this.accessUrls.includes("/process/todoList") && this.$route.name != "todoList") {
               this.$router.push({
                name: "todoList"
               });
            }
        }
    },
    destroyed() {
        if (this.websocket) {
            this.websocket.close();
            this.websocket = null;
        }
    },
    computed: {
        ...mapGetters(['accessUrls'])
    }
};
</script>
